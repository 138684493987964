import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';

import api from '../services/api';

import { Button, Input, Label, Row } from "reactstrap";

import { Modal} from 'react-bootstrap';

import * as toasts from '../utils/toasts';

import AWS from 'aws-sdk';

const s3 = new AWS.S3({
  region: 'sa-east-1',
  accessKeyId: 'AKIASOKURKVNUTAVEH6Z',
  secretAccessKey: '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'
});

const Departamentos = () => {
  const [departamentos, setDepartamentos] = useState([]);
  const [nome, setNome] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [instagram, setInstagram] = useState('');
  const [email, setEmail] = useState('');
  const [descricao, setDescricao] = useState('');
  const [iddepartamento,setIdDepartamento] = useState('');

  const [selectedFile, setSelectedFile] = useState('');
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [resizeAvatar, setResizeAvatar] = useState(false);

  const getDepartamentos = async () => {

    const pesquisa = document.getElementById('pesquisa').value;

    const { data } = await api.get(`/estados?pesquisa=${pesquisa}`);

    setDepartamentos(data);
  }

  const enviarSugestao = async () => {
    
    const sugestao = {
      nome: nome,
      whatsapp: whatsapp,
      instagram: instagram,
      email: email,
      descricao: descricao,
      iddepartamentos: iddepartamento,
      urlanexo: selectedFile
      }
    
    if (!validarCampos(sugestao)){
      return false;
    }

    setShow(false);

    await api.post(`/sugestoes`, sugestao);

    toasts.notifySuccess('Sugestão encaminhada com sucesso');

    setNome('');
    setWhatsapp('');

  }

  const validarCampos = (sugestao) => {
    const termo = document.getElementById('termo').checked;
    const privacidade = document.getElementById('privacidade').checked;
    const { nome } = sugestao;

    if (!nome) {
      toasts.notifyError('Campo nome obrigatório');
      return false;
    }

     if (!whatsapp) {
      toasts.notifyError('Campo whatsapp obrigatório');
      return false;
    }


    if (!email) {
      toasts.notifyError('Campo email obrigatório');
      return false;
    }

    if (!descricao) {
      toasts.notifyError('Campo descricao obrigatório');
      return false;
    }


    if (!termo) {
      toasts.notifyError('Aceite os termos de Política de Privacidade.');
      return false;
    }



    if (!privacidade) {
      toasts.notifyError('Aceite os termos de Política de Privacidade.');
      return false;
    }


    return true;
  }
  const controlarModalEdicao = (id) =>  {
    setIdDepartamento(id);
    setShow(true);
  
  }


  const onChangeSelectedFile = (event) => {
    if (event.target.files[0]) {
      updateAnexo (event.target.files[0])
    }
  }

  const handleLoading = (spinnerName, value) => {
    this.setState({ [spinnerName]: value })
  }

 const updateAnexo = (file) => {
  if (file) {
      const params = {
          Bucket: 'projetosmarcal-files',
          Key: `anexo/${Math.random().toString(15).substring(2, 8).toUpperCase()}-${file.name}`,
          Body: file
      };

      s3.upload(params, async (error, data) => {
          if (error) throw new Error(error.message)
          console.log(data.Location)
          setSelectedFile(data.Location)
        
      });
  }
}
  useEffect(() => {
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => { setShow(true)};
 
  return (
    <>
      <div className="container">
        <br />
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="section-title text-center mb-4 pb-2">
              <h4 className="">Escolha sua cidade.</h4>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-lg-12 col-md-6 ">
            <input 
              type="text" 
              id="pesquisa" 
              className="form-control" 
              placeholder="Digite aqui a sua cidade..."
              style={{marginBottom: '25px'}}
              onChange={getDepartamentos}
            />
          </div>
          <br />
          <div className="col-lg-12 col-md-6 ">
            <button 
              type="text" 
              className="form-control btn btn-primary"
              onClick={getDepartamentos}
            >Pesquisar</button>
          </div>
        </div>
        <Row>                                                 
          <section className="section" style={{ padding: '20px 0'}}>
            <div className="container">
              <div className="row">
                {departamentos.map((departamento) => {
                  return (
                    <>
                      <div className="col-lg-3 col-md-6 mt-4 pt-2">
                        <Link to="/" onClick={()=>controlarModalEdicao(departamento.iddepartamentos)} >
                          <div className="popu-catego ry-box bg-primary rounded text-center p-4" 
                            style={{ 
                              backgroundColor: '#2b54d0 !important;', 
                              minHeight: '116px',
                              minWidth: '200px'
                            }}
                          >
                            <div className="popu-category-content">
                              <h5 className="mb-2 text-light title" style={{color: '#ffffff !important'}}>{departamento.nome}</h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </>
                  )
                })}
              </div>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Encaminhe sua sugestão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form>

                  <div className="form-group app-label">
                    <label>Nome: <span class="text-danger">*</span></label>
                    <div className="row">
                      <div className="col-lg-12">
                        <input type="text" id="nome" className="form-control resume" onChange={e => setNome(e.target.value)} autocomplete="off"/>
                      </div>
                    </div>
                  </div>

                  <div className="form-group app-label">
                    <label>Email: <span class="text-danger">*</span></label>
                    <div className="row">
                      <div className="col-lg-12">
                      <input type="text" id="email" className="form-control resume" onChange={e => setEmail(e.target.value)} autocomplete="off"/>
                      </div>
                    </div>
                  </div>
                  
                  <div className="form-group app-label">
                    <label>Whatsapp: <span class="text-danger">*</span></label>
                    <div className="row">
                      <div className="col-lg-12">
                      <input type="text" id="whatsapp" className="form-control resume" onChange={e => setWhatsapp(e.target.value)} autocomplete="off"/>
                      </div>
                    </div>
                  </div>
                  
                  <div className="form-group app-label">
                    <label>Instagram:</label>
                    <div className="row">
                      <div className="col-lg-12">
                      <input type="text" id="instagram" className="form-control resume" onChange={e => setInstagram(e.target.value)} autocomplete="off"/>
                      </div>
                    </div>
                  </div>
                  
                  <div className="form-group app-label">
                    <label>Descrição: <span class="text-danger">*</span></label>
                    <div className="row">
                      <div className="col-lg-12">
                        <textarea 
                          type="text" 
                          id="descricao" 
                          className="form-control resume"
                          placeholder="Escreva aqui mais detalhes sobre o seu projeto."
                          onChange={e => 
                          setDescricao(e.target.value)} 
                          autocomplete="off" 
                          maxLength={400}
                        />
                      </div>
                    </div>
                  </div>
                
                  <p><strong>Caso tenha mais detalhes do seu projeto, anexe abaixo.</strong></p>
                  <div className="form-group app-label">
                    <label className="btn btn-primary" type="button" htmlFor="inputAvatar">
                      <input
                        id="inputAvatar"
                        type="file"
                        accept="image/*,.pdf,.doc,.docx"
                        onChange={onChangeSelectedFile}
                     />
                    </label>  
                  </div>
                  <br />
                  <div className="col-lg-12">
                      <Input type="checkbox" className="check_termo" id="termo" />
                      <Label className="form-check-label" for="termo">
                          Eu aceito o recebimento de informações de natureza político-eleitoral 
                          da Campanha para Deputado Federal de Pablo Marçal, bem como o fornecimento 
                          e armazenamento dos meus dados pessoais, conforme a Política de Privacidade.&nbsp;
                      <Link 
                      
                        to="#" style={{ color: '#014282' }} 
                        onClick={() => { window.location.
                        replace("https://pablomarcal.com.br/politicadeprivacidadeldr/"); }}>
                          Política de Privacidade. 
                      </Link>
                      </Label>
                  </div>
                  <br />
                  <div className="col-lg-12">
                      <Input type="checkbox" className="check_termo" id="privacidade" />
                      <Label className="form-check-label" for="privacidade">
                          Eu aceito e consinto com o fornecimento e armazenamento dos meus dados sensíveis
                            de opiniões políticas, conforme a Política de Privacidade.&nbsp;
                          <Link
                        
                            to="#" 
                            style={{ color: '#014282' }}
                            onClick={() => { window.location.
                            replace("https://pablomarcal.com.br/politicadeprivacidadeldr/"); }}>
                            Política de Privacidade.
                          </Link>
                          
                      </Label>
                  </div>
                  
                </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button  style={{ backgroundColor: '#014282', maxWidth: '400px' }}variant="primary" onClick={enviarSugestao}>Enviar</Button>
                </Modal.Footer>
              </Modal>
            </div>
          </section>
        </Row>
      </div>
    </>
  );
};

export default Departamentos;
