import { React, useEffect } from 'react';

import Footer from '../componentes/Footer';
import Pesquisa from '../componentes/Pesquisa';
import Departamentos from '../componentes/Departamentos';

const Home = () => {

  useEffect(() => {
  }, []);

  return (
    <>
        <Pesquisa />
        <Departamentos />
        <Footer />
    </>
  );
};

export default Home;