import { React } from 'react';

import bg from '../bg/projetos-pablo2.jpeg';

const Pesquisa = () => {
  return (
    <>
      <section className="bg-home" style={{background: `url("${bg}") center center`}}>
        <div className="bg-overlay" />
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="title-heading text-center text-white">
                    <h1 className="heading font-weight-bold mb-4">O que precisa melhorar em sua Cidade?</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pesquisa;
