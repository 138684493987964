import { React } from 'react';

import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
              <Link to="#"><img src="images/logo-marcal.png" height={40} alt="" /></Link>
              <p className="mt-4">Essa página foi criada com o propósito de conectar pessoas e projetos ao nosso ecossistema.</p>
            </div>
            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <p className="text-white mb-4 footer-list-title">Redes</p>
              <ul className="list-unstyled footer-list">
                <li><Link to={{ pathname: 'https://www.instagram.com/pablomarcal1'}} target="_blank" className="text-foot"><i className="mdi mdi-chevron-right" />Instagram</Link></li>
                <li><Link to={{ pathname: 'https://www.youtube.com/c/PabloMar%C3%A7al'}} target="_blank" className="text-foot"><i className="mdi mdi-chevron-right" />Youtube</Link></li>
                <li><Link to={{ pathname: 'https://t.me/ldroficial'}} target="_blank" className="text-foot"><i className="mdi mdi-chevron-right" />Telegram</Link></li>
              </ul>
              
            </div>
            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <p className="text-white mb-4 footer-list-title f-17">Mais informações</p>
              <ul className="list-unstyled text-foot mt-4 mb-0">
                <li><Link to={{ pathname: 'https://pablomarcal.com.br'}} target="_blank" className="text-foot"><i className="mdi mdi-chevron-right" />pablomarcal.com.br</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer footer-bar">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className>
                <p className="mb-0">Pablo Marçal 2022 | Todos os Direitos Reservados.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
